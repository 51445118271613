import {useState, useEffect} from 'react';
import {useMoralis, useNFTBalances} from 'react-moralis';
import cansAbi from '../contracts/Cans.json';
import {NftResult} from './useVerifyMetadata';

interface CansUnclaimed {
  tokenId: number;
  balance: any;
}

export const useCansUnclaimed = () => {
  //const sodaContract = '0xb184b9414e7d7c436b7097ed2c774bb56fae392f'; // Mainnet
  const sodaContract = '0xf18f53605e43846f80ab1e4af16e5dcf01a35da3'; // Testnet
  const cansContract = '0x63956bf60ec73fa7e61a2a052eb084a66b197c55'; // Testnet
  const {Moralis, isWeb3Enabled} = useMoralis();
  const [unclaimedBalances, setUnclaimedBalances] = useState<CansUnclaimed[]>(
    []
  );

  async function fetchUnclaimedBalance(id: number) {
    const options = {
      contractAddress: cansContract,
      functionName: 'cansUnclaimed',
      abi: cansAbi,
      params: {tokenId: id},
    };
    const message = await Moralis.executeFunction(options);
    const balances = unclaimedBalances.slice();

    setUnclaimedBalances(balances.concat({tokenId: id, balance: message}));
    console.log('Balances:', unclaimedBalances);
    console.log('Balance count', unclaimedBalances.length);
  }

  return {unclaimedBalances, fetchUnclaimedBalance};
};
