import React from 'react';
import ReactDOM from 'react-dom';
import {MoralisProvider} from 'react-moralis';
import {ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import App from './App';
import theme from './theme';

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

function Application() {
  if (!APP_ID || !SERVER_URL)
    throw new Error(
      'Missing Moralis Application ID or Server URL. Make sure to set your .env file.'
    );

  return (
    <MoralisProvider initializeOnMount appId={APP_ID} serverUrl={SERVER_URL}>
      <App />
    </MoralisProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Application />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
