import * as React from 'react';
import {useNFTBalances} from 'react-moralis';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import NFTCard from './NFTCard';

function NFTBalance() {
  //const sodaContract = '0xb184b9414e7d7c436b7097ed2c774bb56fae392f'; // Mainnet
  const sodaContract = '0xf18f53605e43846f80ab1e4af16e5dcf01a35da3'; // Testnet
  const {data: nftBalances} = useNFTBalances();
  const sodas = (
    !nftBalances?.result ? Array.from(new Array(6)) : nftBalances?.result
  ).filter(nft => {
    return nft?.token_address === sodaContract;
  });

  return (
    <Box sx={{flexGrow: 1, width: '100%'}}>
      <Typography variant="h3">NFT Balances</Typography>
      <Grid
        container
        spacing={{xs: 2, md: 3}}
        columns={{xs: 4, sm: 8, md: 12, lg: 16}}
        sx={{margin: '0 auto', width: '100%'}}
      >
        {sodas.map((nft, index) => (
          <Grid item xs={2} sm={4} key={index}>
            <NFTCard nft={nft} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default NFTBalance;
