import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

let theme = createTheme({
  palette: {
    primary: {
      main: '#2952e3',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      mf: 990,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Montserrat'), local('Montserrat-Regular');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
