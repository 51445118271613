import * as React from 'react';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import {NftResult, useVerifyMetadata} from '../hooks/useVerifyMetadata';
import {useCansUnclaimed} from '../hooks/useCansClaiming';

interface NFTCardProps {
  nft?: NftResult;
}

function NFTCard({nft}: NFTCardProps) {
  const {verifyMetadata} = useVerifyMetadata();
  const {fetchUnclaimedBalance} = useCansUnclaimed();

  if (nft) {
    nft = verifyMetadata(nft);
  }

  React.useEffect(() => {
    if (nft) {
      fetchUnclaimedBalance(+nft.token_id);
    }
  }, [nft]);

  return (
    <Card variant="outlined" sx={{maxWidth: 240}}>
      <CardHeader
        title={
          nft ? (
            nft.name
          ) : (
            <Skeleton width="40%" height={10} animation="wave" sx={{mb: 2}} />
          )
        }
        subheader={
          nft ? (
            nft.token_address
          ) : (
            <Skeleton width="80%" height={10} animation="wave" />
          )
        }
      />
      {nft ? (
        <CardMedia
          component="img"
          alt={nft.name}
          height={250}
          image={nft.image || 'error'}
        />
      ) : (
        <Skeleton height={300} animation="wave" variant="rectangular" />
      )}
      <CardContent>
        {nft ? (
          <Typography variant="body2" color="text.secondary" component="p">
            {JSON.stringify(nft)}
          </Typography>
        ) : (
          <React.Fragment>
            <Skeleton height={10} animation="wave" sx={{mb: 2}} />
            <Skeleton width="80%" height={10} animation="wave" />
          </React.Fragment>
        )}
      </CardContent>
      {nft && (
        <CardActions>
          <IconButton size="small">
            <ManageSearchOutlinedIcon />
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
}

export default NFTCard;
