import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import Account from './Account/Account';
import Sidebar from './Sidebar';

const pages = [{title: 'SODA Home', path: '#'}];

function Navbar() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{width: '8rem', mr: 2, display: {xs: 'none', md: 'flex'}}}
            src="https://unsplash.it/396/64"
            alt="logo"
          />

          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="links to SODA pages"
              aria-controls="sidebar-appbar"
              aria-haspopup="true"
              onClick={() => setSidebarOpen(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Sidebar
              open={sidebarOpen}
              setOpen={setSidebarOpen}
              pages={pages}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: {xs: 'flex', md: 'none'},
            }}
          >
            <Box
              component="img"
              sx={{
                width: '8rem',
              }}
              src="https://unsplash.it/396/64"
              alt="logo"
            />
          </Box>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
            {pages.map(({title, path}) => (
              <Button
                key={title}
                onClick={() => setSidebarOpen(false)}
                sx={{my: 2, color: 'white', display: 'block'}}
                href={path}
              >
                {title}
              </Button>
            ))}
          </Box>

          <Box sx={{flexGrow: 0}}>
            <Account />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
