import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import {Cans, Footer, Navbar, Welcome} from './components';
import NFTBalance from './components/NFTBalance';

function App() {
  return (
    <Box minHeight="100vh">
      <Navbar />
      <Container maxWidth={false}>
        <Welcome />
        <NFTBalance />
        <Cans />
        <Footer />
      </Container>
    </Box>
  );
}

export default App;
