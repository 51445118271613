//import {useApiContract} from 'react-moralis';
import {useIPFS} from '../hooks/useIPFS';
import {useFlavors} from '../hooks/useFlavors';

import cansAbi from '../contracts/Cans.json';

const baseCansURI = 'ipfs://QmU39DDfTBhv1WXQwDEsp9mcEQTGtuPm6U1rZDpy9LiPE9/';

const Cans = () => {
  //const cansContract = '0xd386ff8b13e20a943ee0929da4228a32ce4f5261'; // Mainnet
  const cansContract = '0x63956bf60ec73fa7e61a2a052eb084a66b197c55'; // Testnet
  const {FLAVORS, incrementQty, decrementQty, getQty} = useFlavors();

  const Can = (flavor: string) => {
    const {resolveLink} = useIPFS();

    return (
      <div key={flavor}>
        <img
          style={{maxWidth: '100px'}}
          src={resolveLink(baseCansURI + flavor + '.jpg')}
        ></img>
        <button onClick={() => decrementQty(flavor)}>-</button>
        {getQty(flavor)}
        <button onClick={() => incrementQty(flavor)}>+</button>
      </div>
    );
  };

  //const {getUnclaimedTotal, data, error, isLoading, isFetching} =
  //  useApiContract({
  //    address: cansContract,
  //    functionName: 'unclaimedTotal',
  //    abi: cansAbi,
  //    params: {tokenIds: [1, 2, 3]},
  //  });

  //const {claimCans, claimData, claimError, isWaitingForClaim, isClaiming} =
  //  useApiContract({
  //    address: cansContract, // Mainnet
  //    functionName: 'claim',
  //    abi: cansAbi,
  //    params: {tokenIds: [1, 2, 3, 4, 5], amounts: [5, 5, 5, 5, 5, 5]},
  //  });
  const claimCans = () => {};
  const isClaiming = false;

  //runContractFunction();
  //<div>{data && JSON.stringify(data, null, 2)}</div>

  return (
    <div>
      <h1>Cans</h1>
      <div>
        {FLAVORS.map(f => {
          return Can(f);
        })}
      </div>
      <button onClick={() => claimCans()} disabled={isClaiming}>
        Claim Cans
      </button>
    </div>
  );
};

export default Cans;
