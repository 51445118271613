import {useState} from 'react';

export const useFlavors = () => {
  const FLAVORS = [
    'Cherry',
    'Orange',
    'Lemon',
    'Sour_Apple',
    'Blue_Raspberry',
    'Grape',
  ];

  const [quantities, setQuantities] = useState(Array(6).fill(0));

  const setFlavor = (f: string, qty: number) => {
    const qtys = quantities.slice();
    const idx = FLAVORS.indexOf(f);
    qtys[idx] = qty;
    setQuantities(qtys);
  };

  const incrementQty = (f: string) => {
    console.log('Increment: ', f);
    const qtys = quantities.slice();
    const idx = FLAVORS.indexOf(f);
    qtys[idx]++;
    setQuantities(qtys);
  };

  const decrementQty = (f: string) => {
    console.log('Decrement: ', f);
    const qtys = quantities.slice();
    const idx = FLAVORS.indexOf(f);
    if (qtys[idx] === 0) {
      return;
    } else {
      qtys[idx] = qtys[idx] > 0 ? qtys[idx] - 1 : 0;
      setQuantities(qtys);
    }
  };

  const getQty = (f: string) => {
    const idx = FLAVORS.indexOf(f);
    return quantities[idx];
  };

  return {FLAVORS, incrementQty, decrementQty, getQty};
};
